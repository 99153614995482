<!-- 信息动态列表页 -->
<template>
  <div class="w-full _indfolist">
    <!-- 内容 -->
    <div class="cardd">
      <!-- 搜索框 -->
      <div class="w-full flex justify-content">
        <!-- 输入框 -->
        <div class="kuang">
          <el-input
            @keydown.enter.native="handleKeyDown"
            v-model.trim="search"
            placeholder="请输入查找标题"
            :maxlength="50"
          ></el-input>
          <img
            class="fangda"
            @click.stop="fangda"
            src="@/assets/img/one/搜索.png"
            alt=""
          />
        </div>
      </div>
      <!-- 线 -->
      <div class="w-full flex align-items xixian">
        <div class="xian"></div>
        <div class="fontwo">{{ ming }}</div>
        <div class="xian"></div>
      </div>
      <!-- 线 end -->
      <!-- 模板内容 -->
      <div class="heiban">
        <!-- 行 -->
        <div
          class="hang"
          v-for="(item, index) in list"
          :key="index"
          @click="godetail(item.sharesalt)"
        >
          <div class="flex align-items">
            <div class="hongdian"></div>
            <div class="fontthree">{{ item.title }}</div>
          </div>
          <div class="fontfour">{{ item.createtime.slice(0, 10) }}</div>
        </div>
      </div>
      <div class="yema">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :page-sizes="tiaomu"
          :page-size="100"
          layout=" prev, pager, next, sizes, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
<script>
import API from "@/api/modules/infos.js";
export default {
  data() {
    return {
      ming: "", //
      zhi: 3, //0=公告通知,1=网信动态,2=网安知识,3=便民政策,4=全部
      page: 1,
      pageLine: 10,
      search: "",
      list: [],
      tiaomu: [10, 20],
      total: 0,
    };
  },
  mounted() {
    document.getElementsByClassName(
      "el-pagination__jump"
    )[0].childNodes[0].nodeValue = "跳至";
    this.ming = this.$store.state.loginMessage.key;
    this.sharelist();
  },
  methods: {
    //跳转到详情页
    godetail(id) {
      this.$store.state.loginMessage.wid = id;
      window.localStorage.setItem("xin", 1);
      const routeData = this.$router.resolve({
        path: "/infosdetail",
        query: {
          wid: id,
          price:6
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 切换条目
    handleSizeChange(val) {
      this.pageLine = val;
      this.sharelist();
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val;
      this.sharelist();
    },
    // 回车监听
    handleKeyDown(event) {
      this.fangda();
    },
    // 搜索
    fangda() {
      this.page = 1;
      this.sharelist();
    },
    // 返回首页
    fanhui() {
      this.jump("infos", this.$store.state.loginMessage);
    },
    // 列表
    async sharelist() {
      if (this.zhi == 0) {
        this.ming = "公告通知";
      } else if (this.zhi == 1) {
        this.ming = "网信动态";
      } else if (this.zhi == 2) {
        this.ming = "网安知识";
      } else if (this.zhi == 3) {
        this.ming = "便民信息";
      }
      let res = await API.share({
        search: this.search,
        page: this.page,
        pageLine: this.pageLine,
        type: this.zhi,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
  },
  beforeDestroy() {
    delete this.$store.state.loginMessage.name;
    window.localStorage.removeItem("key");
  },
};
</script>
  
<style lang="scss" scoped>
._indfolist {
  width: 1200px;
}
.biao {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin: 16px 0 16px 0;

  .zuojian {
    width: 18px;
    height: 18px;
    margin: 0 3px;
  }

  .yanse {
    color: #0653ac;
  }
}
.chehui {
  ::v-deep(.el-button--primary) {
    width: 110px;
    height: 44px;
    background: #2670c5;
    border-radius: 2px;
    border: 0px solid red;
    margin-right: 17px;
  }

  ::v-deep(.el-button--info) {
    width: 82px;
    height: 44px;
    background: #86898d;
    border-radius: 2px;
  }

  .che {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #2670c5;
    letter-spacing: 0;
    font-weight: 400;

    .huitui {
      width: 15.69px;
      height: 13.07px;
      margin-right: 6.15px;
    }
  }
}
.cardd {
  width: 100%;
  background: #fff;
  padding: 80px 10px 20px 10px;
  box-sizing: border-box;
  margin-top: 20px;
}
.kuang {
  width: 70%;
  height: 42px;
  background: #f8f8f8;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  border-radius: 21px;
}
.fangda {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 10;
  top: calc(50% - 10px);
  right: 20px;
  cursor: pointer;
}
::v-deep(.el-input) {
  height: 100% !important;
}
::v-deep(.el-input__inner) {
  border: 0px solid !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  &::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #484c51;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.xixian {
  margin-top: 40px;
  padding: 0 50px;
  box-sizing: border-box;
}
.heiban {
  margin-top: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  width: 100%;
}
.xian {
  flex: 1;
  height: 1px;
  border-top: 1px solid #e2e2e2;
}
.fontwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #2c74c6;
  font-weight: 400;
  margin: 0 20px;
}
.hang {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}
.hongdian {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ff4800;
  margin-right: 20px;
}
.fontthree {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #191a1c;
  font-weight: 400;
}
.fontfour {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #484c51;
  font-weight: 400;
}
.yema {
  margin: 70px 0 30px 0;
  display: flex;
  justify-content: end;

  ::v-deep(.el-pager) {
    .number {
      background: #fff;
      border: 0.5px solid #d6dbe3;
    }
  }

  ::v-deep(.el-pagination__jump) {
    margin-left: 0 !important;
  }
  ::v-deep(.el-input__inner) {
    height: 28px !important;
    border: 1px solid #d6dbe3 !important;
  }
}
</style>